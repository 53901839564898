// jQuery autoComplete v1.0.5
// //github.com/Pixabay/jQuery-autoComplete
! function(e) {
	e.fn.autoComplete = function(t) {
		var s = e.extend({}, e.fn.autoComplete.defaults, t);
		return "string" == typeof t ? (this.each(function() {
			var s = e(this);
			"destroy" == t && (e(window).off("resize.autocomplete", s.updateSC), s.off("keydown.autocomplete keyup.autocomplete"), s.data("autocomplete") ? s.attr("autocomplete", s.data("autocomplete")) : s.removeAttr("autocomplete"), e(s.data("el")).remove(), s.removeData("el").removeData("autocomplete"))
		}), this) : this.each(function() {
			function t(e) {
				var t = o.val();
				if (o.cache[t] = e, e.length && t.length >= s.minChars) {
					var a = "";
					for (i = 0; i < e.length; i++) a += s.renderItem(e[i], t);
					o.sc.html(a), o.updateSC(0)
				} else o.sc.hide()
			}
			var o = e(this);
			o.sc = e('<div class="autocomplete-suggestions"></div>').addClass(s.menuClass), o.data("el", o.sc).data("autocomplete", o.attr("autocomplete")), o.attr("autocomplete", "off"), o.cache = {}, o.last_val = "", o.updateSC = function(t, s) {
				if (o.sc.css({
						top: o.offset().top + o.outerHeight(),
						left: o.offset().left,
						width: o.outerWidth()
					}), !t && (o.sc.show(), o.sc.maxHeight || (o.sc.maxHeight = parseInt(o.sc.css("max-height"))), o.sc.suggestionHeight || (o.sc.suggestionHeight = e(".autocomplete-suggestion", o.sc).first().outerHeight()), o.sc.suggestionHeight))
					if (s) {
						var a = o.sc.scrollTop(),
							l = s.offset().top - o.sc.offset().top;
						l + o.sc.suggestionHeight - o.sc.maxHeight > 0 ? o.sc.scrollTop(l + o.sc.suggestionHeight + a - o.sc.maxHeight) : 0 > l && o.sc.scrollTop(l + a)
					} else o.sc.scrollTop(0)
			}, e(window).on("resize.autocomplete", o.updateSC), o.sc.appendTo("body"), o.sc.on("mouseleave.autocomplete", ".autocomplete-suggestion", function() {
				e(".autocomplete-suggestion.selected").removeClass("selected")
			}), o.sc.on("mouseenter.autocomplete", ".autocomplete-suggestion", function() {
				e(".autocomplete-suggestion.selected").removeClass("selected"), e(this).addClass("selected")
			}), o.sc.on("mousedown.autocomplete", ".autocomplete-suggestion", function() {
				var t = e(this).data("val");
				o.val(t), s.onSelect(t), setTimeout(function() {
					o.focus()
				}, 10)
			}), o.blur(function() {
				try {
					over_sb = e(".autocomplete-suggestions:hover").length
				} catch (t) {
					over_sb = 0
				}
				over_sb || (o.last_val = o.val(), o.sc.hide())
			}), o.sc.focus(function() {
				o.focus()
			}), o.on("keydown.autocomplete", function(t) {
				if (40 == t.which && o.sc.html()) {
					var s, a = e(".autocomplete-suggestion.selected", o.sc);
					return a.length ? (s = a.next(".autocomplete-suggestion"), s.length ? (a.removeClass("selected"), o.val(s.addClass("selected").data("val"))) : (a.removeClass("selected"), o.val(o.last_val), s = 0)) : (s = e(".autocomplete-suggestion", o.sc).first(), o.val(s.addClass("selected").data("val"))), o.updateSC(0, s), !1
				}
				if (38 == t.which && o.sc.html()) {
					var s, a = e(".autocomplete-suggestion.selected", o.sc);
					if (a.length) {
						var s = a.prev(".autocomplete-suggestion");
						s.length ? (a.removeClass("selected"), o.val(s.addClass("selected").data("val"))) : (a.removeClass("selected"), o.val(o.last_val), s = 0)
					} else s = e(".autocomplete-suggestion", o.sc).last(), o.val(s.addClass("selected").data("val"));
					return o.updateSC(0, s), !1
				}
				27 == t.which && o.val(o.last_val).sc.hide()
			}), o.on("keyup.autocomplete", function(a) {
				if (!~e.inArray(a.which, [27, 38, 40, 37, 39])) {
					var l = o.val();
					if (l.length >= s.minChars) {
						if (l != o.last_val) {
							if (o.last_val = l, clearTimeout(o.timer), s.cache) {
								if (l in o.cache) return void t(o.cache[l]);
								for (i = 1; i < l.length - s.minChars; i++) {
									var c = l.slice(0, l.length - i);
									if (c in o.cache && !o.cache[c].length) return void t([])
								}
							}
							o.timer = setTimeout(function() {
								s.source(l, t)
							}, s.delay)
						}
					} else o.last_val = l, o.sc.hide()
				}
			})
		})
	}, e.fn.autoComplete.defaults = {
		source: 0,
		minChars: 3,
		delay: 100,
		cache: 1,
		menuClass: "",
		renderItem: function(e, t) {
			var s = new RegExp("(" + t.split(" ").join("|") + ")", "gi");
			return '<div class="autocomplete-suggestion" data-val="' + e + '">' + e.replace(s, "<b>$1</b>") + "</div>"
		},
		onSelect: function() {}
	}
}(jQuery);